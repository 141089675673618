import * as React from 'react';
import { HeadFC, Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import Layout from '../components/Layout';
import BackToHomepage from '../assets/back-to-homepage.svg';

import {
  pageStyles,
  headingStyles,
  paragraphStyles,
  imageContainer,
} from '../styles/pages/utility/styles.module.css';

const SomethingWentWrongPage = () => {
  return (
    <Layout>
      <main className={pageStyles}>
        <div className={imageContainer}>
          <StaticImage
            src="../images/Something Went Wrong.png"
            alt="Something Went Wrong Image"
          />
        </div>
        <h1 className={headingStyles}>Woops, something went wrong</h1>
        <p className={paragraphStyles}>
          We are working on fixing the problem, please try again
        </p>
        <div>
          <Link to="/">
            <BackToHomepage />
          </Link>
        </div>
      </main>
    </Layout>
  );
};

export default SomethingWentWrongPage;

export const Head: HeadFC = () => <title>Something Went Wrong</title>;
